<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1>Список ЦЗН и ОИВ субъекта РФ
            <v-select
                label="Регион"
                v-model="region"
                :items="$cznDepartmentsByRegionList"
                item-text="name"
                return-object
                :readonly="$user.role === 'ROLE_REGIONAL_ADMINISTRATOR'"
                class="left-element"
                style="max-width: 300px"
                @change="onSelectChange($event)"></v-select>
            <div class="header-buttons">

              <div class="menu-button search-menu-button" @click="$router.push('/config/departmentEdit')">
                <v-icon class="icon-item icon-account-plus"></v-icon>
              </div>
            </div>
          </h1>
          <table class="anketsRegister-table" >
            <thead>
            <tr>
              <th @click="sortByField('region.code')" style="cursor: pointer">Регион</th>
              <th @click="sortByField('name')" style="cursor: pointer">Название</th>
              <th @click="sortByField('fullName')" style="cursor: pointer">Полное название</th>
              <th @click="sortByField('address')" style="cursor: pointer">Адрес</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="d in departments" :key="'ssdds'+d.id">
              <td>
                {{ d.region.name }}
              </td>
              <td style="white-space: normal;">
                {{ d.name }}
              </td>
              <td style="max-width: 300px; white-space: break-spaces;">
                {{ d.fullName }}
              </td>
              <td style="max-width: 300px; white-space: break-spaces;">
                {{ d.address  }}
              </td>
              <td>
                <div class="edit" @click="$router.push('/config/departmentEdit/' +d.id)">
                  <v-icon class="icon-item icon-note-edit-outline"></v-icon>
                </div>
              </td>
              <td>
              </td>
            </tr>
            </tbody>
          </table>


        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

export default {
  props: {},
  components: {},
  data() {
    return {
      region: null,
      departments: [],
      departmentsFromService: [],
      apiLoaded: false,
      errorText: null,
      sortAsc: false,
      curSortField: null,
    };
  },
  methods: {
    async loadDepartments() {
      let req = await this.$getApi('/department/getList')
      if (req.ok) {
        this.departmentsFromService = req.payload
        this.filterDepartmentsByRegion()
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    filterDepartmentsByRegion(){
      console.log('reg', this.region)
      if(this.region == null){
        this.departments = this.departmentsFromService
      } else {
        this.departments = this.departmentsFromService.filter(
            e=>e.region.code === this.region.code
        )
      }
    },

    sortByField(field){
      if(this.curSortField === field)
        this.sortAsc = !this.sortAsc
      if(this.sortAsc)
        this.departments.sort((a, b) => (a[field] < b[field]) ? 1 : -1)
      else
        this.departments.sort((a, b) => (a[field] > b[field]) ? 1 : -1)
      this.curSortField = field
    },

    onSelectChange(opt){
      this.region = opt;
      this.filterDepartmentsByRegion();
    }
  },
  async beforeMount() {
    if(this.$user.role === 'ROLE_REGIONAL_ADMINISTRATOR'){
      this.region = this.$user.region;
    }
    await this.loadDepartments()
    this.apiLoaded = true
  }
};
</script>
